import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import CourseItems from "./CoursesItems";
import { getCoursesbyTeacherId } from "../../../../actions/Course.action";
import Container from "../Container";
import {Row , Col} from "antd"

const CoursesPage = ({ courses: { courses, loading },getCoursesbyTeacherId ,year }) => {
  useEffect(() => {
    year && getCoursesbyTeacherId( "", year._id);
  }, []);
  return (
    <Container>
      
           <div  id="courses-header" >
           <h3>
              المواد التدريسية
            </h3>
          </div>
          <div id="course-body">
          <Row gutter={16} >
          {courses &&
            courses.map((course) => (
              <Fragment key={course._id}>
                <Col span={8}>
                <CourseItems course={course} />
                </Col>
              </Fragment>
            ))}
            </Row>
            </div>
  
    </Container>
  );
};

const mapStatetoProps = (state) => ({
  courses: state.courses,
  year : state.years.year
});
export default connect(mapStatetoProps, { getCoursesbyTeacherId })(CoursesPage);
