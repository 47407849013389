import React, {  useState ,useEffect } from "react";
import { Modal, Button } from 'antd';
import {useSelector} from "react-redux"
import { connect } from "react-redux";
import CheckboxStudents from "../../students/CheckboxStudents";
import { addStudentToClass } from "../../../actions/Class.action";
import { getStudentsBySchool } from "../../../actions/Student.action";
import { setAlert } from "../../../actions/alert";

const AddStudentToClass = ({ addStudentToClass, setAlert,classID,getStudentsBySchool }) => {
  const students = useSelector(state => state.students.students)
  const {school} = useSelector(state => state.schools)
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
 
  const [myFilters, setMyFilters] = useState([]);
  const [message, setmessage] = useState("");
  const [selectAll, setselectAll] = useState(false);
  let arr = [];
  const handleFilters = (filters) => {
  setMyFilters(filters);
    console.log("students", myFilters);
  };
  const onSubmit = async () => {
    console.log(myFilters , classID)
    if (myFilters.length > 0) {
      setAlert(
        `تم إرسال رساله لعدد ${myFilters.length}   من طلبة هذا المقرر`,
        "success"
      );
      addStudentToClass(myFilters, classID);
      getStudentsBySchool(school._id)
    } else {
      await students.map((s) => arr.push(s.email));
      addStudentToClass(arr, message);
    }
    setIsModalVisible(false);
  };

  return (
      <>
      {/* eslint-disable-next-line */}
      <a onClick={showModal}>
        إضافة الطلبة
      </a>
      <Modal title="اضافة الطلبة للفصل " visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          إضافة
        </Button>,
      ]}
      >
         {!selectAll && students && students !== "" && (
            <CheckboxStudents
              students={students.filter(student => !student.classy || student.classy === "")}
              handleFilters={(filters) => handleFilters(filters)}
            />
          )}
      </Modal>
    </>
  );
};

export default connect(null, { addStudentToClass, setAlert ,getStudentsBySchool})(AddStudentToClass);
