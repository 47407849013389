import React from 'react'
import Spinner from '../../../components/Layout/Spinner'
import Container from "./Container"
import {useSelector} from "react-redux"
import { Timeline } from 'antd';
import EditSchool from '../../../components/modals/School/EditSchool';


const SchoolDetails = ({match}) => {
    const {school , loading} = useSelector(state => state.schools)
    return  (
    <Container url={match.params.url} >
    {loading ? <Spinner /> : 
    <div>
        <h2>بيانات المدرسة </h2>
        
        <br />
       
    <Timeline mode="right">
        <Timeline.Item>اسم المدرسة       : {school&&school.title}</Timeline.Item>
        <Timeline.Item>رمز المدرسة       : {school&&school.Url}</Timeline.Item>
        <Timeline.Item>رمز المدرسة       : {school&&school._id}</Timeline.Item>
        <Timeline.Item> لغة الدراسة      : {school&&school.languege}</Timeline.Item>
        <Timeline.Item> البريد الالكنروني : {school&&school.email}</Timeline.Item>
        <Timeline.Item>  رقم الهاتف      : {school&&school.phone}</Timeline.Item>
        <h3>عنوان المدرسة</h3>
        <Timeline.Item>المدينة           : {school&&school.city}</Timeline.Item>
        <Timeline.Item> الحي             : {school&&school.address}</Timeline.Item>
        <Timeline.Item> الشارع           : {school&&school.street}</Timeline.Item>
        {school && <EditSchool correntSchool={school} />}
    </Timeline>
    
    </div>}    
    </Container>
    )
}

export default SchoolDetails
