import React, {  useState } from "react";
import { Modal, Button, message } from 'antd';
import FormInput from "../../../form-input/form-input.component";
import { changePassword } from "../../../../actions/auth";
import { connect } from "react-redux";

const ChangePassword = ({ changePassword }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [Password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

 

  const { newPassword, confirmNewPassword, oldPassword } = Password;

  const onChange = (e) =>
    setPassword({ ...Password, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      message.error("كلمات المرور غير متطابقة");
      setIsModalVisible(false);
    } else {
      changePassword(oldPassword, newPassword);

      message.success("تم تغيير كلمة المرور بنجاح");

      setIsModalVisible(false);
    }
  };
  return (

<>
      <Button type="primary" onClick={showModal}>
      تغيير كلمة المرور
         </Button>
      <Modal title="تغيير كلمة المرور" visible={isModalVisible} 
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          تغيير
        </Button>,
      ]}>
      <FormInput
              name="oldPassword"
              type="password"
              handleChange={onChange}
              value={oldPassword}
              label="كلمة المرور القديمة"
            />
            <FormInput
              name="newPassword"
              type="password"
              handleChange={onChange}
              value={newPassword}
              label="كلمة المرور الجديدة"
            />

            <FormInput
              name="confirmNewPassword"
              type="password"
              handleChange={onChange}
              value={confirmNewPassword}
              label="تأكيد كلمة المرور الجديدة"
            />
      </Modal>
    </>
      
  );
};
const mapStatetoProps = (state) => ({
  error: state.auth.error,
});
export default connect(mapStatetoProps, { changePassword })(
  ChangePassword
);
