import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Levelpage = () => {
  return (
    <Fragment>
      <div id="level"></div>
      <section id="ll" className="center-align teal-text  my-4">
        <h2 className="mb-5">المرحلة الابتدائية</h2>
        <div className="grid-Style">
          <div>
            <Link to="/grade">
              <img src="/img/Numbers/01.png" alt="One"  />
              <h4>الصف الأول الابتدائي</h4>
            </Link>
          </div>
          <div>
            <Link to="/grade">
              <img src="/img/Numbers/02.png" alt="One" />
              <h4>الصف الثاني الابتدائي</h4>
            </Link>
          </div>
          <div>
            <Link to="/grade">
              <img src="/img/Numbers/03.png" alt="One" />
              <h4>الصف الثالث الابتدائي</h4>
            </Link>
          </div>
          <div>
            <Link to="/grade">
              <img src="/img/Numbers/04.png" alt="One" />
              <h4>الصف الرابع الابتدائي</h4>
            </Link>
          </div>
          <div>
            <Link to="/grade">
              <img src="/img/Numbers/05.png" alt="One" />
              <h4>الصف الخامس الابتدائي</h4>
            </Link>
          </div>
          <div>
            <Link to="/grade">
              <img src="/img/Numbers/06.png" alt="One" />
              <h4>الصف السادس الابتدائي</h4>
            </Link>
          </div>
        </div>
      </section>
      <section id="course" className="bg-my">
        <div className="grid-2">
          <div className="grid-2">
            <div>
              <img src="/img/main.png" alt="coutrse" />
            </div>
            <div>
              <h1>دورات تقوية</h1>
              <p>دورات خاصة للتقوية في هذه المرحلة</p>
            </div>
          </div>
          <div className="grid-3">
            <img src="/img/course1.png" alt="coutrse" />
            <img src="/img/course2.png" alt="coutrse" />
            <img src="/img/en.png" alt="coutrse" />
          </div>
        </div>
      </section>
      <section id="video" className="center-align teal-text py-5">
        <h1 className="mt-5">معرض الفيديو</h1>
        <p>يمكن مشاهدة اهم الدروس العلمية هنا</p>
        <div className=".grid-3 mt-5 center-align">
          <video
            src="/img/scince.mp4"
            width="300"
            height="270"
            controls
            className=" mx-2"
          />
          <video
            src="/img/scince.mp4"
            width="300"
            height="270"
            controls
            className=" mx-2"
          />
          <video
            src="/img/scince.mp4"
            width="300"
            height="270"
            controls
            className=" mx-2"
          />
          <video
            src="/img/scince.mp4"
            width="300"
            height="270"
            controls
            className=" mx-2"
          />
        </div>
      </section>
      <footer className="bg-my center-align mt-5">
        <p className="lead">&copy; 2020</p>
      </footer>
    </Fragment>
  );
};

export default Levelpage;
