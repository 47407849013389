import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getClassesBySchool } from "../../actions/Class.action";
import { getStudentsBySchool } from "../../actions/Student.action";
import AddClass from "../../components/modals/classes/AddClass";
import ClassItems from "./ClassItems";
import Spinner from "../../components/Layout/Spinner";

const StudentsPage = ({
  getClassesBySchool,
  school,
  year,
  getStudentsBySchool,
  classes: { classes,filtered, loading },
  isAuthenticated,
  user,
}) => {
  useEffect(() => {
    getStudentsBySchool(school._id);
    year && getClassesBySchool(school._id ,year._id);
    // eslint-disable-next-line
  }, [loading]);
  return !classes ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid">
          {/* <div className="container center-align ">
            <StudentFilter />
            <SendEmail />
          </div> */}
          
          {year && user.schools && user.schools.length > 0 && 
               user.schools.map(s => s.id._id.toString() === school._id.toString() && s.roll==="admin" && <AddClass schoolid= {school._id}  studyYear={ year._id} />)}
          <table className="striped container-fluid ">
            <thead>
              <tr>
                <th className="right-align"> الفصل</th>
                <th className="right-align"> عدد الطلبة</th>
                <th className="right-align"> عدد المواد</th>
                <th className="right-align"> مسؤول الفصل</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filtered.length > 0
                ? filtered.map((classy) => (
                    <Fragment key={classy._id}>
                      <ClassItems classy={classy} />
                    </Fragment>
                  ))
                : classes.map((classy) => (
                    <Fragment key={classy._id}>
                      <ClassItems classy={classy} />
                    </Fragment>
                  ))}
            </tbody>
          </table>
        </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  classes: state.classes,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  school : state.schools.school,
  year : state.years.year
});
export default connect(mapStateToProps, {
  getClassesBySchool,
  getStudentsBySchool
})(StudentsPage);
