import React, {  useState } from "react";
import { Modal, Button } from 'antd';
import FormInput from "../../../form-input/form-input.component";
import { updateTss } from "../../../../actions/profile";
import { loadUser } from "../../../../actions/auth";
import { connect } from "react-redux";
import { setAlert } from "../../../../actions/alert";

const EDITTss = ({ tss, updateTss, loadUser, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };



  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [Tss, setTss] = useState({
    cofschool: tss.cofschool,
    yofschool: tss.yofschool,
    bacalavra: tss.bacalavra,
    cofbacalavra: tss.cofbacalavra,
    yofbacalvra: tss.yofbacalvra,
    master: tss.master,
    cofmaster: tss.cofmaster,
    yofmaster: tss.yofmaster,
    phd: tss.phd,
    cofphd: tss.cofphd,
    yofphd: tss.yofphd,
    gspecialty: tss.gspecialty,
    specialization: tss.specialization,
    adjective: tss.adjective,
  });


  const {
    cofschool,
    yofschool,
    bacalavra,
    cofbacalavra,
    yofbacalvra,
    master,
    cofmaster,
    yofmaster,
    phd,
    cofphd,
    yofphd,
    gspecialty,
    specialization,
    adjective,
  } = Tss;
  const onChange = (e) => setTss({ ...Tss, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    updateTss(Tss);
    setAlert("تم تعديل البيانات العلمية بنجاح", "success");
    setIsModalVisible(false);
  };

  return (
    tss && (
        <>
      <Button type="primary" onClick={showModal}>
        التعديل على البيانات 
      </Button>
      <Modal title=" التعديل على البيانات" visible={isModalVisible} footer={[
            <Button key="back" onClick={handleCancel}>
              إلغاء الامر
            </Button>,
            <Button key="submit" type="primary"  onClick={onSubmit}>
              تعديل
            </Button>,
          ]}>
     <FormInput
                name="cofschool"
                type="text"
                handleChange={onChange}
                value={cofschool}
                label="التانوي"
              />
              <FormInput
                name="yofschool"
                type="text"
                handleChange={onChange}
                value={yofschool}
                label="السنة الحصول الشهادة الثانوية"
              />

              <FormInput
                name="bacalavra"
                type="text"
                handleChange={onChange}
                value={bacalavra}
                label="البكالوريوس"
              />
              <FormInput
                name="cofbacalavra"
                type="text"
                handleChange={onChange}
                value={cofbacalavra}
                label="مكان الحصول على البكالوريوس"
              />
              <FormInput
                name="yofbacalvra"
                type="text"
                handleChange={onChange}
                value={yofbacalvra}
                label="سنة الحصول على البكالوريوس"
              />

              <FormInput
                name="master"
                type="text"
                handleChange={onChange}
                value={master}
                label="الماجيستير"
              />
              <FormInput
                name="cofmaster"
                type="text"
                handleChange={onChange}
                value={cofmaster}
                label="مكان الحصول علي الماجيستير"
              />
              <FormInput
                name="yofmaster"
                type="text"
                handleChange={onChange}
                value={yofmaster}
                label="سنة الحصول علي الماجيستير"
              />

              <FormInput
                name="phd"
                type="text"
                handleChange={onChange}
                value={phd}
                label="الدكتورا"
              />
              <FormInput
                name="cofphd"
                type="text"
                handleChange={onChange}
                value={cofphd}
                label="مكان الحصول علي الدكتورا"
              />
              <FormInput
                name="yofphd"
                type="text"
                handleChange={onChange}
                value={yofphd}
                label="سنة الحصول علي الدكتورا"
              />

              <FormInput
                name="gspecialty"
                type="text"
                handleChange={onChange}
                value={gspecialty}
                label="التخصص العام"
              />
              <FormInput
                name="specialization"
                type="text"
                handleChange={onChange}
                value={specialization}
                label="التخصص الدقيق"
              />
              <FormInput
                name="adjective"
                type="text"
                handleChange={onChange}
                value={adjective}
                label="الصفة"
              />
      </Modal>
    </>

       
    )
  );
};

export default connect(null, { updateTss, loadUser, setAlert })(EDITTss);
