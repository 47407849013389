import React,{useEffect} from "react"
import { Tabs } from 'antd';
import Container from "./Container";
import { useSelector } from "react-redux";
import MembersPage from "../../../dashBourd/Members/Members.page";
import StudentPage from "../../../dashBourd/Students/Student.page";

const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

const Demo = ({match}) =>{
 
  const school = useSelector(state => state.schools.school)
return (
  <Container >
  <Tabs defaultActiveKey="1" onChange={callback}>
    <TabPane tab="المعلمين" key="1">
      <MembersPage />
    </TabPane>
    <TabPane tab="الطلبة" key="2">
      <StudentPage />
    </TabPane>
    <TabPane tab="المسؤولين" key="3">
    <MembersPage  admin={true}/>
    </TabPane>
  </Tabs>
  </Container>
)};

export default Demo