import React, { useState } from "react";
import { connect  } from "react-redux";
import { UpdateStudyYear } from "../../../actions/stydyYear.action";
import FormInput from "../../form-input/form-input.component";
import { Modal, Button ,Select ,message,DatePicker } from 'antd';
import {FormOutlined } from  "@ant-design/icons"
const { Option } = Select;
const EditCourse = ({
  corrntStudyYear,
  UpdateStudyYear,
}) => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [Year, setYear] = useState({
    id: corrntStudyYear._id,
    nameOfStudyYear :corrntStudyYear.nameOfStudyYear,
    startOfStudyYear : corrntStudyYear.startOfStudyYear,
    endOfStudyYear :corrntStudyYear.endOfStudyYear,
    active : corrntStudyYear.active,
  });

  const { id , nameOfStudyYear , startOfStudyYear , endOfStudyYear , active } = Year;


  const onSubmit = async (e) => {
    e.preventDefault();
    UpdateStudyYear({id , nameOfStudyYear , startOfStudyYear , endOfStudyYear , active });
    message.success("تم التعديل على العام الجامعي");
    setIsModalVisible(false);
  };

 
  return (

<>
     {/* eslint-disable-next-line */}
      <a  onClick={showModal}>
         <FormOutlined />
      </a>
      <Modal title="تعديل علي العام الجامعي" visible={isModalVisible} 
       footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          تعديل
        </Button>,
      ]}>
         <FormInput
              name="nameOfStudyYear"
              type="text"
              handleChange={(e) =>setYear({ ...Year, nameOfStudyYear : e.target.value   })} 
              value={nameOfStudyYear}
              label="العام الدراسي"
              required
            />

              <h4 className="my-1"> بداية العام الدراسي</h4>
              <DatePicker onChange={(date) => setYear({ ...Year, startOfStudyYear : date  })} />

              <h4 className="my-1">نهاية العام الدراسي </h4>
              <DatePicker onChange={(date) => setYear({ ...Year, endOfStudyYear : date  })} />
      </Modal>
    </>
     
  );
};

const mapStateToProps = (state) => ({
  members: state.members,
});
export default connect(mapStateToProps, {
  UpdateStudyYear,
})(EditCourse);
