import React from "react"
import { Tabs } from 'antd';
import Container from "./Container";
import ClassesPage from "../../../dashBourd/Classes/Class.page";
import CoursesPage from "../../../dashBourd/Courses/Courses.page";
import StudyYearsPage from "../../../dashBourd/StudyYears/StudyYears.page"

const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

const Demo = ({match}) =>{
 
return (
  <Container >
  <Tabs defaultActiveKey="1" onChange={callback}>
    <TabPane tab="الفصول الدراسية" key="1">
      <ClassesPage  />
    </TabPane>
    <TabPane tab="المواد الدراسية" key="2">
      <CoursesPage/>
    </TabPane>
    <TabPane tab=" العام الدراسي" key="3">
      <StudyYearsPage  />
    </TabPane>
  </Tabs>
  </Container>
)};

export default Demo