import React, { useEffect } from 'react'
import { Fragment } from 'react'
import {useSelector , useDispatch} from "react-redux"
import Spinner from '../../components/Layout/Spinner'
import {getSchools} from "../../actions/school.action"
import AddSchool from "../../components/modals/School/AddSchool"
import SchoolItems from './SchoolItems'
import SubjectItems from "./SubjectItems"
import AddSubject from "../../components/modals/subjects/AddSubject"
import {getSubjects, subjectFilter} from "../../actions/Subjects.action"
import {Row , Col ,List, Divider} from "antd"
import Container from "./Container"
const Schools = () => {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getSchools())
    dispatch(getSubjects())
    // eslint-disable-next-line
  },[])
 const schooles = useSelector(state => state.schools)
 const {subjects ,filtered} = useSelector(state => state.subjects)
 const user = useSelector(state => state.auth.user)
 const {loading , schools} = schooles
 console.log(schools)
 const data = [
  'الصف الاول',
  'الصف الثاني',
  'الصف الثالث',
  'الصف الرابع',
  'الصف الخامس',
  'الصف السادس',
  'الصف السابع',
];
    return loading ? <Spinner /> : (
            <Fragment>
              <Container>
              <div className="container-fluid">
              <Divider orientation="left">
                  <h1> المدارس  {user && user.Admin && (
                    <Fragment>
                      <AddSchool />
                    </Fragment>
                  )}</h1>
                </Divider>
                  <div className="container center-align ">
                  </div>
                  <table className="striped container-fluid ">
                    <thead>
                      <tr>
                        <th className="right-align">رمز المدرسة</th>
                        <th className="right-align"> اسم المدرسة</th>
                        <th className="right-align"> عدد الطلبة</th>
                        <th className="right-align"> عدد المعلمين</th>
                        <th className="right-align"> العنوان</th>
                        <th className="right-align"> رقم الهاتف</th>
                        <th className="right-align"> الحالة</th>
                        <th className="right-align"> السعر</th>
                        <th className="right-align"> المسؤولين</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      
                        { schools && schools.map((school) => (
                            <Fragment key={school._id}>
                              <SchoolItems school={school} />
                            </Fragment>
                          ))} 
                    </tbody>
                  </table>
                </div>
                <div className="container-fluid">
                   <Divider orientation="left">
                       <h1> المواد الدراسية {user && user.Admin && (
                          <Fragment>
                            <AddSubject />
                          </Fragment>
                          )}</h1>
                       
                     </Divider>
                     <Row gutter={16}>
                         <Col span={4}>
                         <List
                            size="small"
                            header={<div>فلتر </div>}
                            bordered
                            dataSource={data}
                            renderItem={(item , i) => 
                            <List.Item onClick={()=> dispatch(subjectFilter(`${i + 1}`))} style={{cursor :"pointer"}}>{item}
                            </List.Item>}
                          />
                         </Col>
                         <Col span={20}>
                         <Row gutter={16} >
                          {filtered && filtered.length > 0  ? filtered.map((subject) => (
                              <Fragment key={subject._id}>
                                <Col span={7}>
                                <SubjectItems subject={subject} />
                                </Col>
                              </Fragment>
                            )) :
                            <>
                            {subjects &&
                            subjects.map((subject) => (
                              <Fragment key={subject._id}>
                                <Col span={7}>
                                <SubjectItems subject={subject} />
                                </Col>
                              </Fragment>
                            ))}
                            </>
                            }    
                        </Row>
                         </Col>
                     </Row>
                    
                  </div>
                  </Container>
            </Fragment>
    )
}

export default Schools
