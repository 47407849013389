import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getMembersBySchool } from "../../actions/Members.actions";
import AddMember from "../../components/modals/Members/SignUpMember";
import MembersItems from "./MembersItems";
import Spinner from "../../components/Layout/Spinner";
const MembersPage = ({
  getMembersBySchool,
  members: { members, loading },
  schools :{school},
  isAuthenticated,
  user,
  admin
}) => {
  useEffect(() => {
    getMembersBySchool(school._id);
    // eslint-disable-next-line
  }, [loading]);

  let Admins = [];
  
  members &&  members.map(member=> member.schools.map(school => school.roll === "admin" && Admins.push(member)))

  members = admin ? Admins : members
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid">
        <>
        {user.schools && user.schools.length > 0 && !admin &&
               user.schools.map(s => s.id._id.toString() === school._id.toString() && s.roll==="admin" &&
            <Fragment>
              <AddMember schoolId={school._id} admin={admin} />{" "}
            </Fragment>
          )}
          {user && user.Admin && 
          <Fragment>
              <AddMember schoolId={school._id} admin={admin} />{" "}
          </Fragment>
          }
          <table className="striped">
            <thead>
              <tr>
                <th className="right-align">ر.م</th>
                <th className="right-align">الرقم الوطني</th>
                <th className="right-align"> الاسم</th>
                <th className="right-align"> الجنسية</th>
                <th className="right-align"> عنوان السكن</th>
                <th className="right-align"> البريد الالكتروني</th>
                <th className="right-align"> رقم الهاتف</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {members &&
                members.map((member, index) => (
                  <Fragment key={member._id}>
                    <MembersItems member={member} index={index} admin={admin} />
                  </Fragment>
                ))}
            </tbody>
          </table>
        </>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  members: state.members,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  schools: state.schools,
});
export default connect(mapStateToProps, { getMembersBySchool })(MembersPage);
