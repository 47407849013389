import React , { useEffect } from 'react'
import {  Spin ,Divider,} from 'antd';
import {useDispatch ,useSelector} from "react-redux";
import {getLesson} from "../../actions/Lesson.actions"
import Addfile from '../../components/modals/Lectures/Addfile';
import AddfileLink from '../../components/modals/Lectures/AddfileLink';
import LessonItems from "./LessonItems"
import AddText from "../../components/modals/Lessons/addText"
const LessonPage = ({match}) => {
  const dispatch = useDispatch() 

  useEffect(()=>{
    dispatch(getLesson(match.params.id))
    // eslint-disable-next-line
   },[])
   const { lesson , loading } = useSelector(state => state.lessons)
   const { user } = useSelector(state => state.auth)
   
   return !lesson ?  <div className="center">
   <Spin  size="large" />
 </div>  : (
        <div className="container bg-page">
        
       <div>
         <Divider orientation="left">عناصر الدرس</Divider>
         <LessonItems  lesson={lesson}/>
        {lesson && lesson.teacher === user._id  && <div style={{ position: 'fixed', bottom: "10px" ,left : "40vw"}}>
        <AddText correntLesson={lesson} />
        <Addfile lessonID={lesson &&lesson._id}  correntCategory='doc' />
        <AddfileLink lessonID={lesson && lesson._id} />
        <Addfile lessonID={lesson && lesson._id}  correntCategory='image' />
        </div>}
        
      </div>
      </div>
    )
}

export default LessonPage
