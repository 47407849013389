import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col,Button , message } from 'antd';
import {Link} from "react-router-dom"
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import FormInput from "../form-input/form-input.component";
const Login = ({ login, isAuthenticated, user,error }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    Rolls :""
  });

 
  const { email, password,Rolls } = formData;

  const onChange = (e) =>{
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const onSubmit = async () => {
    login(email, password ,Rolls) ;
  };
   
  error && message.error(error)

  if (isAuthenticated && user) {
    if(user.Admin) return <Redirect to="/admin/schools" />;
    if(user.Teacher) return <Redirect to="/teacher" />;
    if(user.Student) return <Redirect to="/student" />;
  }

  return (
    <>
      <section  id="login" >
          <Row gutter={16}>
            <Col className="gutter-row form" md={7} sm={24}>
                <h3>تسجيل الدخول للوحة التحكم / Login</h3>
                <form >
                 
                    <FormInput
                      name="email"
                      type="email"
                      handleChange={(e) => onChange(e)}
                      value={email}
                      label=" البريد الالكتروني / Email"
                      required
                    />
                      <FormInput
                      name="password"
                      type="password"
                      handleChange={(e) => onChange(e)}
                      value={password}                      
                      label="  كلمة المرور / Password"
                      minLength="6"
                      required
                    />
                      
                    
                    <h4 className="text-right"> <Link to="/"  >هل نسيت كلمة المرور؟ </Link></h4>
                    
                    <br />
                    <Button type="primary" block onClick={onSubmit} >  
                      تسجيل الدخول / login
                    </Button>
                    
                </form>
                <h4 className="mt-2">    في النظام لاول مرة؟ <Link to="/signup" > تسجيل </Link></h4>
                
             
            </Col>
            <Col className="gutter-row side " md={15}>
              <div className="login-side"></div>
            </Col>
          </Row>
       
      </section>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  error: state.auth.error,
});

export default connect(mapStateToProps, { login })(Login);
