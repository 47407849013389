import {
  GET_COURSES,
  ERROR_COURSE,
  UPDATE_COURSE,
  DELETE_COURSE,
  GET_COURSE,
  ADD_COURSE,
  CLEAR_COURSE,
  GET_OLDCOURSES,
} from "../actions/types";

const inialState = {
  courses: [],
  course: null,
  oldcourses:[],
  exams: [],
  posts: [],
  post: null,
  lectures: [],
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COURSE:
      return {
        ...state,
        course: payload,
        loading: false,
      };

      case ADD_COURSE:
      return {
        ...state,
        courses: [payload, ...state.courses],
        loading: false,
      };
    case CLEAR_COURSE:
      return {
        ...state,
        course: null,
        loading: false,
      };
    case GET_COURSES:
      return {
        ...state,
        courses: payload,
        loading: false,
      };
     case GET_OLDCOURSES:
       return {
         ...state,
         oldcourses : payload,
         loading: false,
       }
    case UPDATE_COURSE:
      return {
        ...state,
        courses: state.courses.map((course) =>
          course._id === payload._id ? payload : course
        ),
        loading: false,
      };
    case DELETE_COURSE:
      return {
        ...state,
        courses: state.courses.filter((course) => course._id !== payload),
        loading: false,
      };
    case ERROR_COURSE:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
