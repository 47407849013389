import React, { useState } from 'react';
import { Modal, Button , Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setAlert } from "../../../actions/alert";
import {affiliationToSchool} from "../../../actions/Members.actions"

const AffiliationToSchool = ({id}) => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = ({id }) => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [schoolID, setSchoolID] = useState("")

  const onChange = (e) => {
    setSchoolID(  e.target.value );
  };

  const dispatch = useDispatch()
  const handleAdd =() =>{
    if(schoolID!== "") {
      dispatch(affiliationToSchool(id ,schoolID))
    }else{
      setAlert("الحقل فارغ", "success");
    }
    setIsModalVisible(false);
  }
  return (
    <>
      <Button type="primary" shape="circle" icon={<PlusOutlined />} size='large' onClick={showModal}   />

      <Modal title="الانتساب لمدرسة" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={handleAdd}>
          الانتساب
        </Button>,
      ]}
      >
       <Input placeholder="اضافة الرمز" type="text" onChange={onChange} />
      </Modal>
    </>
  );
};

export default AffiliationToSchool
