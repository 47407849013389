import React from "react";
import { Link } from "react-router-dom";
import SignUpMember from "../modals/Members/SignUpMember";
import SignUpStudent from "../modals/students/SignUpStudent"

const HomePage = () => {
  return (
    <section className="showcase center">
      <div className="video-container">
        <video src="/img/scince.mp4" autoPlay muted loop />
      </div>

      <div className="content ">
        <h1 className="center-align ">اختر الشخصية للتسجيل</h1>
        <div className="grid-Style">
          <SignUpStudent />
          <div className="links">
            <Link to="/regester">
              <img src="/img/admin.png" height="470" width="250" alt="ibt" />
              <h3 className="center-align lead text-white">مسؤول</h3>
              </Link>
          </div>
          <SignUpMember />

          <div className="links">
            <Link to="/level">
              <img src="/img/parents.png" height="300" width="250" alt="ibt" />
              <h3 className="center-align lead text-white"> عامة</h3>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePage;
