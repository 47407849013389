import React, {  useState } from "react";
import { Modal, Button } from 'antd';
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addSchool } from "../../../actions/school.action";
import { setAlert } from "../../../actions/alert";
import {PlusCircleOutlined} from "@ant-design/icons"
const AddSchool = ({ addSchool, setAlert }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [School, setSchool] = useState({
  title :"",
  description :"",
  Url :"",
  languege:"",
  city:"",
  street:"",
  address:"",
  email :"",
  phone :"",
  status :"",
  price :"",
  });

  const {  title,
    description,
    Url,
    languege,
    city,
    street,
    address,
    email,
    phone,
    status,
    price } = School;

  const [file, setFile] = useState("");

  const onChangefile = (e) => {
    setFile(e.target.files[0]);
  };
  const onChange = (e) => {
    setSchool({ ...School, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (title === "" || description === "" || file === "") {
      setAlert("ارجو تعبئة كل الحقول و تحميل الصور لهدا للمدرسة", "danger");
    } else {
      addSchool(file, title,description, Url, languege,
        city,
        street,
        address,
        email, phone, status, price);
      setAlert(" تم إضافة المدرسة بنجاح", "success");
      setSchool({
        title :"",
        description :"",
        Url :"",
        languege:"",
          city:"",
          street:"",
          address:"",
          email :"",
        phone :"",
        status :"",
        price :"",
      });
    }
    setIsModalVisible(false);
  };

  return (
     <>
      {/* eslint-disable-next-line */}
      <a  onClick={showModal}>
      <PlusCircleOutlined />
      </a>
      <Modal title="إضافة مدرسة" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
       footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          إضافة
        </Button>,
      ]}
      
      >
      
            <label htmlFor="file"> ادخل صورة للمدرسة </label>
            <br />
            <input id="file" type="file" onChange={onChangefile} />
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="اسم المدرسة"
              required
            />
            <FormInput
              name="Url"
              type="text"
              handleChange={onChange}
              value={Url}
              label="رمز المدرسة باللغة الانجليزية"
              required
            />
             <FormInput
              name="languege"
              type="text"
              handleChange={onChange}
              value={languege}
              label="لغة التدريس"
              required
            />
         
        
        <FormInput
              name="city"
              type="text"
              handleChange={onChange}
              value={city}
              label="المدينة"
              required
            />
            <FormInput
            name="street"
            type="text"
            handleChange={onChange}
            value={street}
            label="الشارع"
            required
          />
            <FormInput
              name="address"
              type="text"
              handleChange={onChange}
              value={address}
              label="العنوان"
              required
            />
             <FormInput
              name="email"
              type="text"
              handleChange={onChange}
              value={email}
              label="البريد الالكتروني"
              required
            />
             <FormInput
              name="phone"
              type="text"
              handleChange={onChange}
              value={phone}
              label="رقم الهاتف"
              required
            />
            <FormInput
              name="price"
              type="number"
              handleChange={onChange}
              value={price}
              label="التسعيرة"
              required
            />
            

            <label htmlFor="firstName" className="active">
              عن المدرسة
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSchool({ ...School, description: data });
              }}
            />
        
      </Modal>
    </>



      
  );
};

export default connect(null, {
  addSchool,
  setAlert,
})(AddSchool);
