import React,{useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import {getExercisesByCourse} from "../../actions/Exercise.actions"
import {clearExercise} from "../../actions/Exercise.actions"
import { List ,Typography} from "antd"
import {PlusCircleOutlined} from "@ant-design/icons"
import DeleteLesson from "../../components/modals/Exercise/DeleteExercise"
import EditLesson from '../../components/modals/Exercise/EditExercise'
import {Link} from "react-router-dom"
const Topics = ({id}) => {
  const dispatch = useDispatch()
  useEffect(()=>{
    console.log(id)
    dispatch(getExercisesByCourse(id))
    // eslint-disable-next-line
  },[])
  const {exercises} = useSelector(state => state.exercises)
  const {school} = useSelector(state => state.schools)
  const {user} = useSelector(state => state.auth)

  const ClearLesson = () =>{
    exercises && dispatch(clearExercise())
}
  return (
    <>
        <List
        header={<h1> التمارين و والوجبات</h1>}
        className="mt-1"
         size="small"
         bordered
         dataSource={exercises}
         renderItem={(item , i) => <List.Item>
        <h3> <Typography.Text >{i+1} .</Typography.Text>
        <Link to={`/teacher/${school.Url}/exercise/${item._id}`} onClick={ClearLesson} 
        style={{textDecoration: "none" ,fontSize :"1.1rem" ,color : "black"}}
        > 
         {item.title}
         </Link>
         </h3>
         {item && item.teacher === user._id && <><DeleteLesson id={item._id} courseId= {id} />
         <EditLesson correntExercise={item} courseId= {id} /> </>} 
       </List.Item>}
       />
       </>
  )
}

export default Topics
