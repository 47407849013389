import React from "react";
import "./Home.styles.scss";
import { connect } from "react-redux";
import UserInfo from "./homeComponents/userInfo";
import Spinner from "../../../components/Layout/Spinner";
import Container from "./Container"
import {Link} from "react-router-dom"
import AffiliationToSchool from "../../../components/modals/students/AffiliationToSchool";
import {Row , Col} from "antd"

const Home = ({ user }) => {
  return !user ? (
    <Spinner />
  ) : (
    <Container >
        <div >
          <Row>
          <Col  sm={24} xs={24} md={8}>
            <UserInfo user={user} />
          </Col>
          <Col xs={24} sm={24} md={16}>
            <div className="text-center my-3">
             
            <h3 className ="my-2 ">
                المدرسة
            </h3>
            {user  && <> 
               {user.school &&user.school._id && user.school._id.length > 0  ?
                  <div className ="my-2">
                    <Link  className="card-link" to={`/student/${user.school.Url}`}>
                    الدخول للوحة  للمدرسة
                    </Link> 
                  </div> : <AffiliationToSchool id={user._id} />
                } 
            </>}
           
            
            </div>
            
            
          </Col>
         
        
        </Row>
      </div>
    </Container> 
  );
};

const mapStatetoProps = (state) => ({
  user: state.auth.user,
  tss: state.tss.tss,
});
export default connect(mapStatetoProps)(Home);
