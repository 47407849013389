import React from "react";
import "./Home.styles.scss";
import { connect } from "react-redux";
import UserInfo from "./homeComponents/userInfo";
import TssInfo from "./homeComponents/tssInfo";
import Spinner from "../../../components/Layout/Spinner";
import Container from "./Container"
import {Link} from "react-router-dom"
import AffiliationToSchool from "../../../components/modals/Members/AffiliationToSchool";
import {Row , Col} from "antd"
const Home = ({ user }) => {
  return !user ? (
    <Spinner />
  ) : (
    <Container >
        <Row gutter={16}>
          <Col md={8} sm={24}>
            <UserInfo user={user} />
          </Col>
          <Col md ={16} sm={24}>
            <TssInfo tss={user.tss} tse={user.tse} tsm={user.tsm} />
            
            <div className="text-center my-3">
             
            <h3 className ="my-2 ">
                المدارس
            </h3>
            {user  && <> 
               {user.schools && user.schools.length > 0 && 
               user.schools.map(school =>
                <>
                 { school.roll === "admin" && 
                  <div className ="my-2">
                    <Link  className="card-link" to={`/school/${school.id.Url}`}>
                       الدخول للوحة التحكم في المدرسة كمسؤول 
                    </Link>
                  </div>
                 } 
                
                  <div className ="my-2">
                    <Link  className="card-link" to={`/teacher/${school.id.Url}`}>
                      الدخول للوحة التحكم في المدرسة كآستاذ
                    </Link> 
                  </div>
                 
                
                 </> )
                } 
            </>}
           
            <AffiliationToSchool id={user._id} />
            </div>
            
            
          </Col>
         
        
        </Row>
    </Container> 
  );
};

const mapStatetoProps = (state) => ({
  user: state.auth.user,
  tss: state.tss.tss,
});
export default connect(mapStatetoProps)(Home);
