import React, {  useState } from "react";
import { Modal, Button } from 'antd';

import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { addSubject } from "../../../actions/Subjects.action";
import { setAlert } from "../../../actions/alert";
import {PlusCircleOutlined} from "@ant-design/icons"

const AddSubject = ({ addSubject, error, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const [subject, setSubject] = useState({
    subjectname: "",
    about: "",
    klass: "",
  });

  const { subjectname, about, klass } = subject;

  const [Image, setImage] = useState("");

  const onChangefile = (e) => {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/gif"
    ) {
      setImage(e.target.files[0]);
    } else {
      setAlert(
        "نوع الصورة غير مطابق  يجب ان تكون مطابقة لاحدي هذه الانواع jpg,gif,png,jpeg",
        "danger"
      );
      setIsModalVisible(false);

    }
  };

  const onChange = (e) => {
    setSubject({ ...subject, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    if (Image && Image !== "" && subjectname !== "") {
      addSubject(Image, subjectname, about, klass);
      setAlert("تم إضافة المقرر الدراسي بنجاح", "success");
      setSubject({
        subjectname: "",
        about: "",
        klass: "",
      });
      setImage("");
    } else {
      setAlert(" يجب ادخال الصورة و اسم الماذة ", "danger");
    }
    setIsModalVisible(false);
  };

  return (
   <>
       {/* eslint-disable-next-line */}
       <a  onClick={showModal}>
        <PlusCircleOutlined />
      </a>
      <Modal title="إضافة ماذة" visible={isModalVisible} 
       footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary" onClick={onSubmit}>
          اضافة
        </Button>,
      ]}>
      <label htmlFor="file"> ادخل صورة توضيحية للمادة</label>
            <br />

            <input id="file" type="file" onChange={onChangefile} />

            <FormInput
              name="subjectname"
              type="text"
              handleChange={onChange}
              value={subjectname}
              label="إسم المادة"
              required
            />

            <FormInput
              name="about"
              type="text"
              handleChange={onChange}
              value={about}
              label="عن الماذة"
              required
            />
            <FormInput
              name="klass"
              type="text"
              handleChange={onChange}
              value={klass}
              label="المرحلة"
              required
            />
      </Modal>
    </>

  );
};
export default connect(null, {
  addSubject,
  setAlert,
})(AddSubject);
