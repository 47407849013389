import React from "react";
import { connect } from "react-redux";
import AddStudentsToClass from "../../components/modals/classes/addStudentToClass"
import EditClass from "../../components/modals/classes/EditClass";
import DeleteClass from "../../components/modals/classes/DeleteClass";
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';


const StudentItem = ({ classy, isAuthenticated, user }) => {
  const { _id, NumberOfClass, letterOfClass,  courses, students} = classy;
  
  return (
    <tr>
      <td className="right-align">{NumberOfClass + letterOfClass}</td>
      <td className="right-align">{students.length}</td>
      <td className="right-align">{courses.length}</td>
      <td className="right-align"></td>
      <td className="right-align">
      <Dropdown overlay={
        (<Menu>
          <Menu.Item>
            <EditClass correntClass={classy}/>
          </Menu.Item>
          <Menu.Item danger>
            <DeleteClass id={_id}/>
          </Menu.Item>
          <Menu.Item danger>
            <AddStudentsToClass classID={_id} students={students}/>
          </Menu.Item>
        </Menu>)}>
        {/* eslint-disable-next-line */}
        <a className="ant-dropdown-link" >
          المزيد <DownOutlined />
        </a>
      </Dropdown>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps)(StudentItem);
