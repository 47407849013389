import React, {  useState } from "react";
import { Modal, Button } from 'antd';
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { updateSchool } from "../../../actions/school.action";
import { setAlert } from "../../../actions/alert";

const UpdateSchool = ({
  updateSchool,
  correntSchool,
  setAlert,
}) => {
  
  console.log(correntSchool)
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [School, setSchool] = useState({
    id: correntSchool._id,
    title: correntSchool.title,
    description: correntSchool.description,
    Url: correntSchool.Url,
    languege : correntSchool.languege,
    city: correntSchool.city,
    street:correntSchool.street,
    address:correntSchool.address,
    email:correntSchool.email,
    phone:correntSchool.phone,
  });


  const { title, description, Url ,
    languege,
    city,
    street,
    address,
    email,
    phone,
  } = School;

  const onChange = (e) => {
    setSchool({ ...School, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    updateSchool(School);
    setAlert("تم التعديل على بيانات المدرسة ", "success");
    setIsModalVisible(false);
  };

  return (

<>
 {/*  eslint-disable-next-line */}
 <a onClick={showModal}>
 التعديل علي بيانات المدرسة      
 </a>

      <Modal title=" التعديل علي بيانات المدرسة" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          تعديل
        </Button>,
      ]}>
            <FormInput
              name="title"
              type="text"
              handleChange={onChange}
              value={title}
              label="عنوان المدرسة"
              
            />
            <FormInput
              name="Url"
              type="text"
              handleChange={onChange}
              value={Url}
              label="رمز المدرسة باللغة الانجليزية"
              
            />
             <FormInput
              name="languege"
              type="text"
              handleChange={onChange}
              value={languege}
              label="لغة التدريس"
              
            />
             <FormInput
              name="city"
              type="text"
              handleChange={onChange}
              value={city}
              label="المدينة"
              
            />
            <FormInput
              name="street"
              type="text"
              handleChange={onChange}
              value={street}
              label="الشارع"
              
            />
            <FormInput
              name="address"
              type="text"
              handleChange={onChange}
              value={address}
              label="الحي"
              
            />
            <FormInput
              name="email"
              type="text"
              handleChange={onChange}
              value={email}
              label="البريد الالكتروني"
              
            />
             <FormInput
              name="phone"
              type="text"
              handleChange={onChange}
              value={phone}
              label="رقم الهاتف"
              
            />
            <label htmlFor="firstName" className="active">
              عن المدرسة
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSchool({ ...School, description: data });
              }}
            />
       
      </Modal>
    </>

  );
};

export default connect(null, {
  updateSchool,
  setAlert,
})(UpdateSchool);
