import axios from "axios";
import {
  GET_MEMBERS,
  ADD_MEMBER,
  ERROR_MEMBER,
  UPDATE_MEMBER,
  ADD_SCHOOL_TO_MEMBER,
  GET_MEMBER,
  DELETE_MEMBER,
} from "./types";

// Add Member
export const register = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(formData);

  try {
    const res = await axios.post("/api/members", body, config);

    dispatch({
      type: ADD_MEMBER,
      payload: res.data,
    });
    console.log(res.data);
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// Reset Password
export const ResetPassword = (_id) => async (dispatch) => {
  try {
    await axios.put(`/api/members/${_id}`);
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

//get One Member
export const getOneMember = (_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/members/${_id}`);
    dispatch({
      type: GET_MEMBER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};



export const BlockingMember = (_id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/members/${_id}/block`);
    dispatch({
      type: UPDATE_MEMBER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const addAdminToSchool = (id ,schoolID) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/members/${id}/schooladmin` , {schoolID});
    dispatch({
      type: UPDATE_MEMBER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const removwAdminFromSchool = (id ,schoolID) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/members/${id}/removeschooladmin` , {schoolID});
    dispatch({
      type: UPDATE_MEMBER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};



export const affiliationToSchool = (_id ,schoolID) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/members/${_id}/school` , {schoolID});
    dispatch({
      type: ADD_SCHOOL_TO_MEMBER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

// GET Members
export const getMembers = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/members");
    dispatch({
      type: GET_MEMBERS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: { msg: error.response, status: error.response },
    });
  }
};
// get Members by school 
export const getMembersBySchool = (_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/members/school/${_id}`);
    dispatch({
      type: GET_MEMBERS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: { msg: error.response, status: error.response },
    });
  }
};


// delete Member
export const deleteMember = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`/api/members/${id}`);
    dispatch({
      type: DELETE_MEMBER,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
