import React, {  useState } from "react";
import { Modal, Button, message } from 'antd';
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { addTss } from "../../../actions/profile";
const AddTss = ({ addTss }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [Tss, setTss] = useState({
    cofschool: "",
    yofschool: "",
    bacalavra: "",
    cofbacalavra: "",
    yofbacalvra: "",
    master: "",
    cofmaster: "",
    yofmaster: "",
    phd: "",
    cofphd: "",
    yofphd: "",
    gspecialty: "",
    specialization: "",
    adjective: "",
  });



  const {
    cofschool,
    yofschool,
    bacalavra,
    cofbacalavra,
    yofbacalvra,
    master,
    cofmaster,
    yofmaster,
    phd,
    cofphd,
    yofphd,
    gspecialty,
    specialization,
    adjective,
  } = Tss;
  const onChange = (e) => setTss({ ...Tss, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    addTss(Tss);
    message.success("تمت عملية الإضافة بنجاح", "success");
  };

  return (
       <>
      <Button type="primary" onClick={showModal}>
      إضافة البيانات العلمية{" "}
      </Button>
      <Modal title="إضافة البيانات العلمية" visible={isModalVisible}    footer={[
            <Button key="back" onClick={handleCancel}>
              إلغاء الامر
            </Button>,
            <Button key="submit" type="primary"  onClick={onSubmit}>
              إضافة
            </Button>,
          ]}>
      <FormInput
              name="cofschool"
              type="text"
              handleChange={onChange}
              value={cofschool}
              label="الثانوي"
              required
            />
            <FormInput
              name="yofschool"
              type="text"
              handleChange={onChange}
              value={yofschool}
              label="سنة الحصول على الشهادة الثانوية"
              required
            />

            <FormInput
              name="bacalavra"
              type="text"
              handleChange={onChange}
              value={bacalavra}
              label="البكالوريوس"
              required
            />
            <FormInput
              name="cofbacalavra"
              type="text"
              handleChange={onChange}
              value={cofbacalavra}
              label="مكان الحصول على البكالوريوس"
              required
            />
            <FormInput
              name="yofbacalvra"
              type="text"
              handleChange={onChange}
              value={yofbacalvra}
              label="سنة الحصول على البكالوريوس"
              required
            />

            <FormInput
              name="master"
              type="text"
              handleChange={onChange}
              value={master}
              label="الماجيستير"
            />
            <FormInput
              name="cofmaster"
              type="text"
              handleChange={onChange}
              value={cofmaster}
              label="مكان الحصول على الماجيستير"
            />
            <FormInput
              name="yofmaster"
              type="text"
              handleChange={onChange}
              value={yofmaster}
              label="سنة الحصول على الماجيستير"
            />

            <FormInput
              name="phd"
              type="text"
              handleChange={onChange}
              value={phd}
              label="الدكتوراه"
            />
            <FormInput
              name="cofphd"
              type="text"
              handleChange={onChange}
              value={cofphd}
              label="مكان الحصول على الدكتوراه"
            />
            <FormInput
              name="yofphd"
              type="text"
              handleChange={onChange}
              value={yofphd}
              label="سنة الحصول على الدكتوراه"
            />

            <FormInput
              name="gspecialty"
              type="text"
              handleChange={onChange}
              value={gspecialty}
              label="التخصص العام"
              required
            />
            <FormInput
              name="specialization"
              type="text"
              handleChange={onChange}
              value={specialization}
              label="التخصص الدقيق"
            />
            <FormInput
              name="adjective"
              type="text"
              handleChange={onChange}
              value={adjective}
              label="الصفة"
              required
            />
      </Modal>
    </>

  );
};

export default connect(null, { addTss })(AddTss);
