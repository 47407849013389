import React, { Fragment, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getCoursesBySchool } from "../../actions/Course.action";
import { getSubjects } from "../../actions/Subjects.action";
import { getMembersBySchool } from "../../actions/Members.actions";
import CourseItems from "./CoursesItems";
import AddCourse from "../../components/modals/courses/AddCourse"
import Spinner from "../../components/Layout/Spinner";


const CoursePage = ({ getCoursesBySchool,getSubjects,getMembersBySchool, courses: { courses, loading },school ,year}) => {
  useEffect(() => {
    year && getCoursesBySchool(school._id ,year._id);
    
    getMembersBySchool(school._id)
    getSubjects()
    // eslint-disable-next-line
  }, [loading]);
  const {user} = useSelector(state => state.auth)
  return !courses ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid">
        {year && user.schools && user.schools.length > 0 && 
               user.schools.map(s => s.id._id.toString() === school._id.toString() && s.roll==="admin" && <AddCourse schoolId={school._id} studyYear={year._id}/>)}
        
        </div>
          <table className="striped container-fluid ">
            <thead>
              <tr>
                <th className="right-align"></th>
                <th className="right-align">اسم المادة</th>
                <th className="right-align"> عدد الوحدات</th>
                <th className="right-align">   استاذ المادة</th>
                <th className="right-align"></th>
              </tr>
            </thead>
            <tbody>
            {courses &&
                      courses.map((course) => (
                            <Fragment key={course._id}>
                              <CourseItems course={course} />
                            </Fragment>
                          ))}
            </tbody>
          </table>
        
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  courses: state.courses,
  user: state.auth.user,
  school : state.schools.school,
  year : state.years.year,
});
export default connect(mapStateToProps, { getCoursesBySchool,getSubjects,getMembersBySchool })(CoursePage);
