import React from "react";
import { connect } from "react-redux";
import { removeStudentfromClasses } from "../../../actions/Student.action";
import {HistoryOutlined } from "@ant-design/icons"
import { Popconfirm, message ,Button} from 'antd';

const SaveToArchive = ({ removeStudentfromClasses, id ,Disabled }) => {
  function confirm(e) {
    removeStudentfromClasses(id);
    message.success('تم أرشفة العام الدراسي');
  }
  
  function cancel(e) {
    console.log(e);
    message.error('تم إلغاء العملية');
  }


  return (
    <Popconfirm
    title="هل انت متأكد من ذلك"
    onConfirm={confirm}
    onCancel={cancel}
    okText="نعم"
    cancelText="لا"
  >
    <br />
    {/* eslint-disable-next-line */}
    <Button danger disabled={Disabled}><HistoryOutlined /> تجديد </Button>
  </Popconfirm>

  );
};
export default connect(null, {
  removeStudentfromClasses,
})(SaveToArchive);
