import React from "react";

const Gradepage = () => {
  return (
    <section>
      <div className="grid-Style container-fluid">
        <div className="card">
          <img src="/img/subject/8.png" width="250" alt="ibt" />
          <a className="btn btn-block btn-my">مادة الحاسب الالي</a>
          <a src="" className="btn btn-block btn-dark mt1">
            تحميل كتاب المادة
          </a>
        </div>
        <div className="card">
          <img src="/img/subject/4.png" width="250" alt="ibt" />
          <a className="btn btn-block btn-my">الرياضيات</a>
          <a src="" className="btn btn-block btn-dark mt1">
            تحميل كتاب المادة
          </a>
        </div>
        <div className="card">
          <img src="/img/subject/3.png" width="250" alt="ibt" />
          <a className="btn btn-block btn-my">اللغة العربية</a>
          <a src="" className="btn btn-block btn-dark mt1">
            تحميل كتاب المادة
          </a>
        </div>
        <div className="card">
          <img src="/img/subject/15.png" width="250" alt="ibt" />
          <a className="btn btn-block btn-my">اللغة الانجليزية</a>
          <a src="" className="btn btn-block btn-dark mt1">
            تحميل كتاب المادة
          </a>
        </div>
      </div>
    </section>
  );
};

export default Gradepage;
