import React, { Fragment } from "react";
import AddTss from "../../../../components/modals/Members/AddTss";
import EDITTss from "../../../../components/modals/Members/edit/EditTss";

const TssInfo = ({ tss }) => {
  return (
    <div>
      <div className="card ">
        <h2>البيانات العلمية</h2>
        {tss && tss !== "" ? (
          <Fragment>
            <div className="grid-3">
              <h4>
                {" "}
                الثانوي : <span className="muted">{tss.cofschool}</span>
              </h4>
              <h4>
                {" "}
                سنة الحصول عليها :{" "}
                <span className="muted">{tss.yofschool}</span>
              </h4>
            </div>
            <div className="grid-3">
              <h4>
                {" "}
                البكالوريوس : <span className="muted">{tss.bacalavra}</span>
              </h4>
              <h4>
                {" "}
                مكان الحصول : <span className="muted">{tss.cofbacalavra}</span>
              </h4>
              <h4>
                {" "}
                سنة الحصول عليها :{" "}
                <span className="muted">{tss.yofbacalvra}</span>
              </h4>
            </div>
            <div className="grid-3">
              <h4>
                {" "}
                الماجستير : <span className="muted">{tss.master}</span>
              </h4>
              <h4>
                {" "}
                مكان الحصول : <span className="muted">{tss.cofmaster}</span>
              </h4>
              <h4>
                {" "}
                سنة الحصول عليها :{" "}
                <span className="muted">{tss.yofmaster}</span>
              </h4>
            </div>
            {tss.phd && tss.phd !== "" && (
              <div className="grid-3">
                <h4>
                  {" "}
                  الماجستير : <span className="muted">{tss.phd}</span>
                </h4>
                <h4>
                  {" "}
                  مكان الحصول : <span className="muted">{tss.cofphd}</span>
                </h4>
                <h4>
                  {" "}
                  سنة الحصول عليها : <span className="muted">{tss.yofphd}</span>
                </h4>
              </div>
            )}

            <div className="grid-2">
              <h4>
                {" "}
                التخصص العام: <span className="muted">{tss.gspecialty}</span>
              </h4>
              <h4>
                {" "}
                التخصص الدقيق :{" "}
                <span className="muted">{tss.specialization}</span>
              </h4>
            </div>

            <h4>
              {" "}
              الصفة : <span className="muted">{tss.adjective}</span>
            </h4>

            <EDITTss tss={tss} />
          </Fragment>
        ) : (
          <AddTss />
        )}
      </div>
    </div>
  );
};

export default TssInfo;
